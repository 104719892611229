import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ReadyToWork = props => (
  <StaticQuery
    query={graphql`
      query ReadyQuery {
        readyCta: file(relativePath: { eq: "ready-cta.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section className="wrapper-slim cta-ready">
        <Img
          fluid={data.readyCta.childImageSharp.fluid}
          className="cta-image"
        />
        <div className="container-medium container--chunky">
          <div className="grid-x grid-margin-x small-only-rhythm">
            <div className="cell small-12 medium-7 cta_message">
              <h3 className="type-title cta-ready_title type-uppercase">
                Ready to work with us<span className="type-highlight">?</span>
              </h3>
            </div>
            <div className="cell small-12 medium-4 medium-offset-1 text-align--center">
              <Link to="/contact" title="Get in touch" className="btn-alpha">
                Get in touch
              </Link>
              <div className="sep-text-center">
                <span className="sep-text_content sep-ready_content">or</span>
              </div>
              <a
                href="/services"
                title="View our services"
                className="ghost_btn-alpha"
              >
                View our services
              </a>
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

export default ReadyToWork
